
import { ref, watch } from 'vue';
import moment from 'moment';
import CardTable from '@/components/kt-datatable/CardTable.vue';
import ApiService from '@/core/services/ApiService';

export default {
  name: 'PartnerVerify',
  components: {
    CardTable,
  },
  setup() {
    const breadcrumbs = ['Partner Account', ['Account Verification']];
    const tableHeader = [
      {
        key: 'no',
        name: 'No.',
        sortable: true,
      },
      {
        name: 'User',
        key: 'profileImage',
        sortable: true,
      },
      {
        key: 'name',
        sortable: false,
      },
      {
        name: 'Role',
        key: 'role',
        sortable: true,
      },
      {
        name: 'Date Created',
        key: 'createdAt',
        sortable: true,
      },
      {
        name: 'Verification Details',
        key: 'details',
        sortable: false,
      },
    ];

    const data = ref<any>([]);
    const loading = ref(true);
    const tableRef = ref<any>(null);

    const addRole = (array, role) => {
      array.forEach((item) => {
        item.role = role;
      });
      return array;
    };

    const getData = async (rows, page) => {
      loading.value = true;

      const driver = await ApiService.get(
        `partner?driverVerifyState=WaitReviewVerify&page=${page}&pageSize=${rows}`
      );
      const staff = await ApiService.get(
        `partner?staffVerifyState=WaitReviewVerify&page=${page}&pageSize=${rows}`
      );
      const organization = await ApiService.get(
        `organization?verifyState=WaitReviewVerify&page=${page}&pageSize=${rows}`
      );

      data.value = [
        ...addRole(driver.data.datas, 'Driver'),
        ...addRole(staff.data.datas, 'Staff'),
        ...organization.data.datas,
      ];

      loading.value = false;
    };

    watch(
      () => [tableRef.value?.rowsPerPage, tableRef.value?.currentPage],
      async ([rows, page]) => {
        await getData(rows, page);
      }
    );

    return {
      tableHeader,
      breadcrumbs,
      data,
      loading,
      moment,
      tableRef,
    };
  },
};
